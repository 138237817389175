import { createSlice } from "@reduxjs/toolkit";
import { InitialState } from "./interfaces";

const initialState: InitialState = {
    isSidebarToggled: false,
    isContentToggled: false
};

const adminHeader = createSlice({
    name: 'adminHeader',
    initialState,
    reducers: {
        setIsSidebarToggled: (state) => {
            state.isSidebarToggled = !state.isSidebarToggled;
        },
        setIsContentToggled: (state) => {
            state.isContentToggled = !state.isContentToggled;
        }
    }
});

export default adminHeader.reducer;
export const { setIsSidebarToggled, setIsContentToggled } = adminHeader.actions;