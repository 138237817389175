// Стилистические импорты
import './header.scss';
import { Twirl as Hamburger } from 'hamburger-react'

// Хуки
import { useNavigate } from 'react-router-dom';

// Вспомогательные компоненты
import Menu from './Menu';
import Globe from './Globe';
import LoginButton from './LoginButton';
import ProfileButton from './ProfileButton';

// Redux
import { useAppDispatch, useAppSelector } from '../../hooks/state';
import { toggleIsHamburgerToggled } from './headerSlice';

const Header = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const isHamburgerToggled = useAppSelector((state) => state.header);
    const isAuthorized = useAppSelector((state) => state.auth.isAuthorized);

    return (
        <header className="header">
            <div className="header__logo" onClick={() => navigate('/')}>
                <img src="https://travelseasons.kz/static/assets/images/logo.svg" alt='logo'/>
            </div>
            <Menu/>
            <div className='header__btns'>
                {isAuthorized ? <ProfileButton/> : <LoginButton/>}
                <Globe/>
                <Hamburger size={24} duration={0.5} toggled={isHamburgerToggled} toggle={() => dispatch(toggleIsHamburgerToggled())}/>
            </div>
        </header>
    )
};

export default Header;