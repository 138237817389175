import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../hooks/state";
import { setIsHamburgerToggled } from "./headerSlice";

// Файлы локализации
import getLocale from "../../services/getLocale";
import ru from './locale/ru.json';
import kk from './locale/kk.json';
import en from './locale/en.json';

const Menu = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const isHamburgerToggled = useAppSelector((state) => state.header);
    const menuTransformClass = isHamburgerToggled ? ' header__menu-active' : '';

    // Начало блока локализации
    const lang = useAppSelector((state) => state.lang.lang);
    const locale = getLocale(lang, {ru: ru, kk: kk, en: en});
    // Конец блока локализации

    return <ul className={'header__menu' + menuTransformClass}>
        <li className='header__menu-item' onClick={() => {
            navigate('/');
            dispatch(setIsHamburgerToggled(false));
        }}>{locale?.menu.main}</li>
        <li className='header__menu-item' onClick={() => {
            navigate('/photos');
            dispatch(setIsHamburgerToggled(false));
        }}>{locale?.menu.photos}</li>
        <li className='header__menu-item' onClick={() => {
            navigate('/organizers');
            dispatch(setIsHamburgerToggled(false));
        }}>{locale?.menu.organizers}</li>
        <li className='header__menu-item' onClick={() => {
            navigate('/contacts');
            dispatch(setIsHamburgerToggled(false));
        }}>{locale?.menu.contacts}</li>
    </ul>
};

export default Menu;