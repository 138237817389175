import { createSlice } from "@reduxjs/toolkit";

const initialState: boolean = false;

const header = createSlice({
    name: 'header',
    initialState,
    reducers: {
        setIsHamburgerToggled: (state, action) => state = action.payload,
        toggleIsHamburgerToggled: state => state = !state
    }
});

export default header.reducer;
export const { setIsHamburgerToggled, toggleIsHamburgerToggled } = header.actions;