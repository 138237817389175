const getLocale = (lang: string, locales: {
    ru: any,
    kk: any,
    en: any
}) => {
    switch (lang) {
        case 'ru':
            return locales.ru
        case 'kk':
            return locales.kk
        case 'en':
            return locales.en
        default:
            break;
    };
};

export default getLocale;