import { createSlice } from "@reduxjs/toolkit";
import { HistoryInitialState } from "./interfaces";

const initialState: HistoryInitialState = {
    urlHistory: []
};

const history = createSlice({
    name: 'history',
    initialState,
    reducers: {
        setLastUrl: (state, action) => {
            state.urlHistory.push(action.payload);
        },
        removeLastUrl: (state) => {
            state.urlHistory.pop();
        }
    }
});

export default history.reducer;
export const { removeLastUrl, setLastUrl } = history.actions;