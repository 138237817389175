import type { CustomFlowbiteTheme } from 'flowbite-react';

export const ToggleTheme: CustomFlowbiteTheme = {
    toggleSwitch: {
        root: {
            base: "group relative flex items-center rounded-lg focus:outline-none"
        },
        toggle: {
            base: "toggle-bg rounded-full border group-focus:ring-4 group-focus:ring-cyan-500/25",
            checked: {
                color: {
                    blue: "bg-main-yellow border-main-yellow"
                }
            },
            sizes: {
                md: "min-w-[38.5px] h-6 after:absolute after:top-[2px] after:left-[2px] after:h-5 after:w-5"
            }
        }
    }
};