import { createAsyncThunk, createEntityAdapter, PayloadAction, createSlice, EntityId } from "@reduxjs/toolkit";
import { Album } from "../../../models/interfaces";
import { _apiBase, deleteRequest, getRequest, postRequest, putRequest } from "../../../hooks/useHttp";
import sortListByField from "../../../services/sortList";
import { RootState } from "../../../store";
import { useAppSelector } from "../../../hooks/state";
import { AlbumEditForm } from "../albumEditForm/interfaces";

export const albumsAdapter = createEntityAdapter<Album>();

const initialState = albumsAdapter.getInitialState({
    albumsSortedBy: 'id',
    albumsLoadingState: 'idle'
});

export const getAllAlbums = createAsyncThunk<Album[] | null>(
    'albums/getAllOrganizers',
    async () => {
        const response = await getRequest(`${_apiBase}albums/`);

        if (!response.message) {
            response.forEach((album: Album) => {
                album.isChecked = false;
            });

            return response as Album[];
        };

        return null as null;
    }
);

export const sortAlbums = createAsyncThunk<Album[], string>(
    'albums/sortAlbums',
    async (field) => {
        const currentAlbums = useAppSelector(selectAll);

        const sortedAlbums = sortListByField(currentAlbums as Album[], field);

        return sortedAlbums as Album[];
    }
);


export const deleteAlbum = createAsyncThunk<void, EntityId[]>(
    'albums/deleteAlbum',
    async (albumIds) => {
        return await deleteRequest(`${_apiBase}albums/`, { albumIds: albumIds }, {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('access_token')}`
        });
    }
);

export const editAlbum = createAsyncThunk<Album, { albumId: number, data: AlbumEditForm }>(
    'albums/editAlbum',
    async (arg) => {
        return await putRequest(`${_apiBase}update-album/${arg.albumId}`, {...arg.data}, {
            "Content-Type": "multipart/form-data",
            "Accept": "application/json",
            'Authorization': `Bearer ${localStorage.getItem('access_token')}`
        });
    }
);

export const createAlbum = createAsyncThunk<void, AlbumEditForm>(
    'albums/createAlbums',
    async (data: AlbumEditForm) => {
        return await postRequest(`${_apiBase}albums/`, data, {
            "Content-Type": "multipart/form-data",
            "Accept": "application/json",
            'Authorization': `Bearer ${localStorage.getItem('access_token')}`
        });
    }
);

const albums = createSlice({
    name: 'albums',
    initialState,
    reducers: {
        setAllAlbumsChecked: (state, action: PayloadAction<boolean>) => {
            const updatedAlbums = state.ids.map(id => {
                const album = state.entities[id];
                return {
                  ...album,
                  isChecked: action.payload
                };
            });

            albumsAdapter.upsertMany(state, updatedAlbums);
        },
        setAlbumsListSortedBy: (state, action: PayloadAction<string>) => {
            state.albumsSortedBy = action.payload;
        },
        toggleAlbumIsChecked: (state, action: PayloadAction<number>) => {
            const album = state.entities[action.payload];
            const updatedAlbum = {
                ...album,
                isChecked: !album.isChecked
            };

            albumsAdapter.upsertOne(state, updatedAlbum);
        }
    },
    extraReducers: (builder) => {
        builder
        .addCase(getAllAlbums.pending, state => {state.albumsLoadingState = 'loading'})
        .addCase(getAllAlbums.fulfilled, (state, action) => {
            state.albumsLoadingState = 'idle';
            if (action.payload) {
                const data = sortListByField(Array.isArray(action.payload) ? action.payload : [action.payload], state.albumsSortedBy)
                albumsAdapter.setAll(state, data as Album[]);
            }
        })
        .addCase(getAllAlbums.rejected, state => {state.albumsLoadingState = 'error'})
        .addCase(sortAlbums.pending, state => {state.albumsLoadingState = 'sorting'})
        .addCase(sortAlbums.fulfilled, state => {state.albumsLoadingState = 'idle'})
        .addCase(sortAlbums.rejected, state => {state.albumsLoadingState = 'error'})
        .addCase(deleteAlbum.pending, state => {state.albumsLoadingState = 'deleting'})
        .addCase(deleteAlbum.fulfilled, state => {state.albumsLoadingState = 'idle'})
        .addCase(deleteAlbum.rejected, state => {state.albumsLoadingState = 'error'})
        .addCase(editAlbum.pending, state => {state.albumsLoadingState = 'editing'})
        .addCase(editAlbum.fulfilled, state => {state.albumsLoadingState = 'idle'})
        .addCase(editAlbum.rejected, state => {state.albumsLoadingState = 'error'})
        .addCase(createAlbum.pending, state => {state.albumsLoadingState = 'creating'})
        .addCase(createAlbum.fulfilled, state => {state.albumsLoadingState = 'idle'})
        .addCase(createAlbum.rejected, state => {state.albumsLoadingState = 'error'})
        .addDefaultCase(() => {})
    }
});

export const { selectAll, selectById } = albumsAdapter.getSelectors<RootState>(state => state.albums)

export default albums.reducer;
export const { setAllAlbumsChecked, setAlbumsListSortedBy, toggleAlbumIsChecked } = albums.actions;