import { createSlice } from "@reduxjs/toolkit";
import { InitialState } from "./interfaces";
import { set } from 'lodash';

const initialState: InitialState = {
    title: '',
    date: new Date().toString(),
    time: '',
    venue: '',
    isMain: false,
    isAvailableForRegister: true
};

const createEventForm = createSlice({
    name: 'createEvent',
    initialState,
    reducers: {
        resetForm: (state) => {
            return initialState;
        },
        setData: (state, action) => {
            const { value, path } = action.payload;
            set(state, path, value);
        },
    }
});

export default createEventForm.reducer;
export const { setData, resetForm } = createEventForm.actions;