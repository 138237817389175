import { createAsyncThunk, createEntityAdapter, createSlice, PayloadAction } from "@reduxjs/toolkit";

// Интерфейсы
import { RegData } from "../../../models/interfaces";
import { RootState } from "../../../store";
import { _apiBase } from "../../../hooks/useHttp";

// Redux
import { setEventRegistrations } from "../../../pages/layouts/admin/event/eventSlice";

// Вспомогательные функции
import { getRequest } from "../../../hooks/useHttp";
import sortListByField from "../../../services/sortList";

export const eventRegistrationsAdapter = createEntityAdapter<RegData>();

const initialState = eventRegistrationsAdapter.getInitialState({
    registrationsLoadingState: 'idle',
    regsSortedBy: 'id'
});

export const getEventRegistrations = createAsyncThunk<RegData[] | null, string>(
    'eventRegistrationsList/getEventRegistrations',
    async (eventId: string, {dispatch}) => {
        const response = await getRequest(`${_apiBase}events/${eventId}/registrations/`, { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` });

        if (!response.message) {
            response.forEach((reg: RegData) => {
                reg.isChecked = false;
            });

            dispatch(setEventRegistrations(response));

            return response as RegData[];
        };

        return null as null;
    }
);

const eventRegistrationsList = createSlice({
    name: 'eventRegistrationsList',
    initialState,
    reducers: { },
    extraReducers: (builder) => {
        builder
        .addCase(getEventRegistrations.pending, state => {state.registrationsLoadingState = 'loading'})
        .addCase(getEventRegistrations.fulfilled, (state, action: PayloadAction<RegData[] | null>) => {
            state.registrationsLoadingState = 'idle';
            if (action.payload) {
                const data = sortListByField(Array.isArray(action.payload) ? action.payload : [action.payload], state.regsSortedBy)
                eventRegistrationsAdapter.setAll(state, data as RegData[]);
                setEventRegistrations(data);
            } else {
                eventRegistrationsAdapter.setAll(state, []);
        }})
        .addCase(getEventRegistrations.rejected, state => {state.registrationsLoadingState = 'error'})
        .addDefaultCase(() => {})
    }
});

export default eventRegistrationsList.reducer;

export const { selectAll, selectById } = eventRegistrationsAdapter.getSelectors<RootState>(state => state.eventRegistrationsList)