import { createSlice } from "@reduxjs/toolkit";
import { set } from 'lodash';
import { InitialState } from "./interfaces";

const initialState: InitialState = {
    typePassword: '',
    retypePassword: ''
};

const reset = createSlice({
    name: 'reset',
    initialState,
    reducers: {
        resetForm: (state) => {
            state = initialState;
        },
        setData: (state, action) => {
            const { value, path } = action.payload;
            set(state, path, value);
        }
    }
});

export default reset.reducer;
export const { resetForm, setData } = reset.actions;