import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { InitialState } from "./interfaces";
import { User } from "../../../models/interfaces";
import { RegData } from "../../../models/interfaces";

const initialState: InitialState = {
    currentUser: {
        id: 0,
        username: '',
        role: '',
        firstName: '',
        lastName: '',
        company: '',
        email: '',
        phone: '',
        photo: '',
        isChecked: false
    },
    currentUserRegs: [],
    regsSortedBy: '',
    loadingState: 'idle'
};

const user = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setCurrentUser: (state, action: PayloadAction<User>) => {
            state.currentUser = action.payload
        },
        resetCurrentUser: (state) => {
            state.currentUser = initialState.currentUser
        },
        setCurrentUserRegs: (state, action: PayloadAction<RegData[]>) => {
            state.currentUserRegs = action.payload
        },
        setCurrentUserRegsSortedBy: (state, action: PayloadAction<string>) => {
            state.regsSortedBy = action.payload;
        },
        setCurrentUserRegsState: (state, action: PayloadAction<string>) => {
            state.loadingState = action.payload
        }
    }
});

export default user.reducer;
export const {
    setCurrentUser,
    resetCurrentUser,
    setCurrentUserRegsSortedBy,
    setCurrentUserRegs,
    setCurrentUserRegsState
} = user.actions;