// Стилистические импорты
import "./App.scss";
import Spinner from "../spinner/Spinner";

// Хуки
import useAPI from "../../hooks/useApi";
import { useAppSelector } from "../../hooks/state";
import { useEffect, useLayoutEffect } from "react";

// Маршрутизация
import { lazy, Suspense } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import SpinnerPage from "../../pages/SpinnerPage";

// Компонент для автоматического обновления токенов авторизации
import TokenRefresher from "../tokenRefresher/TokenRefresher";

// Работа с метаданными
import { HelmetProvider } from "react-helmet-async";

// Уведомления
import { Toaster } from "react-hot-toast";

// Redux
import { useAppDispatch } from "../../hooks/state";
import { setPolicyContent } from "./policySlice";
import { setLang } from "./langSlice";

// Интерфейсы
import { Policy } from "../../models/interfaces";

const PublicPage = lazy(() => import("../../pages/PublicPage"));
const AdminPage = lazy(() => import("../../pages/AdminPage"));

const App = () => {
    const { checkAuthorization, getPolicy } = useAPI();
    const dispatch = useAppDispatch();

    const role = useAppSelector((state) => state.user.currentUser.role);
    const lang = useAppSelector((state) => state.lang.lang);

    useLayoutEffect(() => {
        const lang = localStorage.getItem('lang');
        if (lang) {
            dispatch(setLang(lang));
            getPolicy(`privacy-policy-${lang}`)
            .then((data: Policy) => {

                if (data) {
                    dispatch(setPolicyContent(data.content));
                };
            });
        } else {
            localStorage.setItem('lang', 'ru');
        };
        checkAuthorization();
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        getPolicy(`privacy-policy-${lang}`)
        .then((data: Policy) => {

            if (data) {
                dispatch(setPolicyContent(data.content));
            };
        });
        
        // eslint-disable-next-line
    }, [lang])

    const adminWay = () => {
        if (role === 'admin' || role === 'owner') {
            return <Route path="/admin/*" element={<AdminPage />} />;
        } else if (role === 'anon') {
            return null;
        } else {
            return <Route path="/admin/*" element={<SpinnerPage />} />;
        };
    };

    return (
        <Router>
            <HelmetProvider>
                <div className="App">
                    {/* Компонент для обновления токенов */}
                    <TokenRefresher />
                    <Suspense
                        fallback={
                            <Spinner />
                        }
                    >
                        <Routes>
                            <Route path="/*" element={<PublicPage />} />
                            {adminWay()}
                        </Routes>
                    </Suspense>

                    {/* Компонент для размещения уведолений */}
                    <Toaster />
                </div>
            </HelmetProvider>
        </Router>
    );
};

export default App;
