import { configureStore } from '@reduxjs/toolkit';

import adminHeader from '../components/admin/header/adminHeaderSlice';
import albums from '../components/admin/albumsList/albumsSlice';
import albumCreateForm from '../components/admin/albumCreateForm/albumCreateFormSlice';
import albumCreateModal from '../components/admin/albumCreateModal/albumCreateModalSlice';
import albumEditForm from '../components/admin/albumEditForm/albumEditFormSlice';
import albumEditModal from '../components/admin/albumEditModal/albumEditModalSlice';
import auth from '../components/app/authSlice';
import appProcess from '../components/app/processSlice';
import contactCreateForm from '../components/admin/contactCreateForm/contactCreateFormSlice';
import contactCreateModal from '../components/admin/contactCreateModal/contactCreateModalSlice';
import contactEditForm from '../components/admin/contactEditForm/contactEditFormSlice';
import contactEditModal from '../components/admin/contactEditModal/contactEditModalSlice';
import contacts from '../components/admin/contactsList/contactsSlice';
import createEventForm from '../components/admin/createEventForm/createEventFormSlice';
import deleteModal from '../components/admin/deleteModal/deleteModalSlice';
import editEvent from '../pages/layouts/admin/editEvent/editEventSlice';
import editEventForm from '../components/admin/editEventForm/editEventFormSlice';
import event from '../pages/layouts/admin/event/eventSlice';
import eventGallery from '../components/eventGallery/eventGallerySlice';
import eventRegistrationsList from '../components/admin/eventRegistrationsList/eventRegistrationsListSlice';
import eventsList from '../components/admin/eventsList/eventsListSlice';
import header from '../components/header/headerSlice';
import history from '../components/app/historySlice';
import lang from '../components/app/langSlice';
import login from '../components/loginForm/loginSlice';
import mainEvent from '../components/mainEvent/mainEventSlice';
import organizerCreateForm from '../components/admin/organizerCreateForm/organizerCreateFormSlice';
import organizerCreateModal from '../components/admin/organizerCreateModal/organizerCreateModalSlice';
import organizerEditForm from '../components/admin/organizerEditForm/organizerEditFormSlice';
import organizerEditModal from '../components/admin/organizerEditModal/organizerEditModalSlice';
import organizers from '../components/admin/organizersList/organizersSlice';
import policy from '../components/app/policySlice';
import policyEditor from '../components/admin/policyEditor/policyEditorSlice';
import privacyPolicy from '../components/privacyPolicy/privacyPolicySlice';
import registration from '../components/registrationForm/registrationSlice';
import registrationPage from '../pages/layouts/admin/registration/registrationSlice';
import resetSlice from '../components/resetForm/resetSlice';
import user from '../pages/layouts/user/userSlice';
import userAddForm from '../components/admin/userAddForm/userAddFormSlice';
import userAddModal from '../components/admin/userAddModal/userAddModalSlice';
import userSettings from '../components/userSettingsForm/userSettingsSlice';
import userView from '../pages/layouts/admin/user/userViewSlice';
import users from '../components/admin/usersList/usersSlice';

const store = configureStore({
    reducer: {
        adminHeader,
        albums,
        albumCreateForm,
        albumCreateModal,
        albumEditForm,
        albumEditModal,
        appProcess,
        auth,
        contactCreateForm,
        contactCreateModal,
        contactEditForm,
        contactEditModal,
        contacts,
        createEventForm,
        deleteModal,
        editEvent,
        editEventForm,
        event,
        eventGallery,
        eventRegistrationsList,
        eventsList,
        header,
        history,
        lang,
        login,
        mainEvent,
        organizerCreateForm,
        organizerCreateModal,
        organizerEditForm,
        organizerEditModal,
        organizers,
        policy,
        policyEditor,
        privacyPolicy,
        registration,
        registrationPage,
        resetSlice,
        user,
        userAddForm,
        userAddModal,
        users,
        userSettings,
        userView
    },
    devTools: process.env.NODE_ENV !== 'production'
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;