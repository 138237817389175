import { createSlice } from "@reduxjs/toolkit";
import { set } from 'lodash';
import { AuthData } from "../../models/interfaces";

const initialState: AuthData = {
    email: '',
    password: '',
    remember: false
};

const login = createSlice({
    name: 'login',
    initialState,
    reducers: {
        resetForm: (state) => {
            state = initialState;
        },
        setData: (state, action) => {
            const { value, path } = action.payload;
            set(state, path, value);
        }
    }
});

export default login.reducer;
export const { resetForm, setData } = login.actions;