import { createAsyncThunk, createEntityAdapter, createSlice, EntityId, PayloadAction } from "@reduxjs/toolkit";
import { Contact } from "../../../models/interfaces";
import { _apiBase, deleteRequest, getRequest, postRequest, putRequest } from "../../../hooks/useHttp";
import sortListByField from "../../../services/sortList";
import { RootState } from "../../../store";
import { ContactCreateForm } from "../contactCreateForm/interfaces";
import { ContactEditForm } from "../contactEditForm/interfaces";

export const contactsAdapter = createEntityAdapter<Contact>();

const initialState = contactsAdapter.getInitialState({
    contactsSortedBy: 'id',
    contactsLoadingState: 'idle'
});

export const getAllContacts = createAsyncThunk<Contact[]>(
    'contacts/getAllContacts',
    async () => {
        const response = await getRequest(`${_apiBase}contacts/`);

        if (!response.message) {
            response.forEach((contact: Contact) => {
                contact.isChecked = false;
            });

            return response as Contact[];
        };

        return [] as Contact[];
    }
);

export const addContact = createAsyncThunk<void, ContactCreateForm>(
    'contacts/addContact',
    async (data) => {
        return await postRequest(`${_apiBase}contacts/`, data, {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('access_token')}`
        });
    }
);

export const deleteEvent = createAsyncThunk<void, number[] >(
    'eventsList/deleteEvent',
    async (eventIds) => {
        const response = await deleteRequest(`${_apiBase}events/`, { event_ids: eventIds}, {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('access_token')}`
        });
        return response;
    }
);

export const deleteContact = createAsyncThunk<void, EntityId[]>(
    'contacts/deleteContact',
    async (contactIds) => {
        return await deleteRequest(`${_apiBase}contacts/`, { contactIds: contactIds }, {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('access_token')}`
        });
    }
);

export const editContact = createAsyncThunk<Contact, { contactId: number, data: ContactEditForm }>(
    'contacts/editContact',
    async (arg) => {
        const response = await putRequest(`${_apiBase}contacts/`, {contactId: arg.contactId, data: arg.data}, {
            "Content-Type": "multipart/form-data",
            "Accept": "application/json",
            'Authorization': `Bearer ${localStorage.getItem('access_token')}`
        });
        return response;
    }
);

const contacts = createSlice({
    name: 'contacts',
    initialState,
    reducers: {
        toggleContactIsChecked: (state, action: PayloadAction<number>) => {
            const contact = state.entities[action.payload];
            const updatedContact = {
                ...contact,
                isChecked: !contact.isChecked
            };

            contactsAdapter.upsertOne(state, updatedContact);
        }
    },
    extraReducers: (builder) => {
        builder
        .addCase(getAllContacts.pending, state => {state.contactsLoadingState = 'loading'})
        .addCase(getAllContacts.fulfilled, (state, action) => {
            state.contactsLoadingState = 'idle';
            if (action.payload) {
                const data = sortListByField(Array.isArray(action.payload) ? action.payload : [action.payload], state.contactsSortedBy)
                contactsAdapter.setAll(state, data as Contact[]);
            }
        })
        .addCase(getAllContacts.rejected, state => {
            state.contactsLoadingState = 'idle';
            console.log('Contacts loading error or there are no events');
            contactsAdapter.setAll(state, [] as Contact[]);
        })
        .addCase(addContact.pending, state => {state.contactsLoadingState = 'adding'})
        .addCase(addContact.fulfilled, state => {state.contactsLoadingState = 'idle'})
        .addCase(addContact.rejected, state => {state.contactsLoadingState = 'error'})
        .addCase(deleteContact.pending, state => {state.contactsLoadingState = 'deleting'})
        .addCase(deleteContact.fulfilled, state => {state.contactsLoadingState = 'idle'})
        .addCase(deleteContact.rejected, state => {state.contactsLoadingState = 'error'})
        .addCase(editContact.pending, state => {state.contactsLoadingState = 'editing'})
        .addCase(editContact.fulfilled, state => {state.contactsLoadingState = 'idle'})
        .addCase(editContact.rejected, state => {state.contactsLoadingState = 'error'})
        .addDefaultCase(() => {})
    }
});

export const { selectAll, selectById } = contactsAdapter.getSelectors<RootState>(state => state.contacts)

export default contacts.reducer;
export const { toggleContactIsChecked } = contacts.actions;