import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { PolicyInitialState } from "./interfaces";

const initialState: PolicyInitialState = {
    content: null
};

const privacy = createSlice({
    name: 'privacyEditor',
    initialState,
    reducers: {
        setPolicyContent: (state, action: PayloadAction<object>) => {
            state.content = action.payload;
        }
    }
});

export default privacy.reducer;
export const {
    setPolicyContent
} = privacy.actions;