import type { CustomFlowbiteTheme } from 'flowbite-react';

export const SelectTheme: CustomFlowbiteTheme = {
    select: {
        field: {
            select: {
                colors: {
                    gray: "bg-gray-50 border-gray-300 text-gray-900 focus:border-main-yellow focus:ring-main-yellow"
                },
                withAddon: {
                    off: "rounded-[3px]"
                }
            }
        }
    }
};