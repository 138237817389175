import { createSlice } from "@reduxjs/toolkit";

const initialState: boolean = false;

const privacyPolicy = createSlice({
    name: 'privacyPolicy',
    initialState,
    reducers: {
        setIsPrivacyOpen: state => state = !state
    }
});

export default privacyPolicy.reducer;
export const { setIsPrivacyOpen } = privacyPolicy.actions;