import { useNavigate } from "react-router-dom";
import { Button, Flowbite } from "flowbite-react";
import { ButtonTheme } from "../../style/flowbiteThemes";

// locale
import getLocale from "../../services/getLocale";
import ru from './locale/ru.json';
import kk from './locale/kk.json';
import en from './locale/en.json';

// Redux
import { useAppSelector } from "../../hooks/state";

const LoginButton = () => {
    const navigate = useNavigate();

    // Начало блока локализации
    const lang = useAppSelector((state) => state.lang.lang);
    const locale = getLocale(lang, {ru: ru, kk: kk, en: en});
    // Конец блока локализации

    return (
        <Flowbite theme={{ theme: ButtonTheme}}>
            <Button onClick={() => navigate('/login')} className='header__btns-login' color='yellow'>{locale.signIn}</Button>
        </Flowbite>
    );
};

export default LoginButton;