import type { CustomFlowbiteTheme } from 'flowbite-react';

export const FileInputTheme: CustomFlowbiteTheme = {
    fileInput: {
        field: {
            input: {
                base: "rounded-[3px] overflow-hidden block w-full border disabled:cursor-not-allowed disabled:opacity-50",
                colors: {
                    gray: "bg-gray-50 border-gray-300 text-gray-900 focus:border-main-yellow focus:ring-main-yellow"
                }
            }
        }
    }
};