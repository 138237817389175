import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { set } from 'lodash';
import { InitialState, SetDataPayload } from "./interfaces";
import { EventData } from "../../../models/interfaces";

const initialState: InitialState = {
    formData: null
};

const editEventForm = createSlice({
    name: 'editEvent',
    initialState,
    reducers: {
        resetForm: (state) => {
            state.formData = initialState.formData;
        },
        setData: (state, action: PayloadAction<SetDataPayload>) => {
            const { value, path } = action.payload;
            set(state!, path, value);
        },
        setDefaultFormData: (state, action: PayloadAction<EventData>) => {
            state.formData = action.payload;
        }
    }
});

export default editEventForm.reducer;
export const { resetForm, setData, setDefaultFormData } = editEventForm.actions