import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { InitialState, SetDataPayload } from "./interfaces";
import { EventData, RegData } from "../../../../models/interfaces";
import { set } from 'lodash';

const initialState: InitialState = {
    targetEvent: null,
    eventRegistrations: [],
    regsSortedBy: 'id'
};

const event = createSlice({
    name: 'event',
    initialState,
    reducers: {
        resetForm: (state) => {
            state = initialState;
        },
        setData: (state, action: PayloadAction<SetDataPayload>) => {
            const { value, path } = action.payload;
            set(state, path, value);
        },
        setTargetEvent: (state, action: PayloadAction<EventData | null>) => {
            if (action.payload) {
                state.targetEvent = action.payload;
            } else {
                state.targetEvent = null;
            };
        },
        setEventRegistrations: (state, action: PayloadAction<RegData[]>) => {
            state.eventRegistrations = action.payload
        },
        setRegsSortedBy: (state, action: PayloadAction<string>) => {
            state.regsSortedBy = action.payload
        },
        setAllRegsChecked: (state, action: PayloadAction<boolean>) => {
            state.eventRegistrations.forEach((reg) => reg.isChecked = action.payload)
        },
        setRegIsChecked: (state, action: PayloadAction<number>) => {
            state.eventRegistrations[action.payload].isChecked = !state.eventRegistrations[action.payload].isChecked
        }
    }
});

export default event.reducer;
export const {
    resetForm,
    setData,
    setTargetEvent,
    setEventRegistrations,
    setRegsSortedBy,
    setAllRegsChecked,
    setRegIsChecked
} = event.actions;