// Стилистические компоненты
import './publicPage.scss';
import Spinner from '../components/spinner/Spinner';

// Вспомогательные компоненты
import Header from '../components/header/Header';

const SpinnerPage = () => {
  return (
    <>
      <Header />
      <Spinner/>
    </>
  );
};

export default SpinnerPage;
