import { createSlice } from "@reduxjs/toolkit";
import { set } from 'lodash';
import { InitialState } from "./interfaces";
import { PayloadAction } from "@reduxjs/toolkit";

const initialState: InitialState = {
    formData: {
        personType: 'exhibitor',
        isPrivacyAgree: false,
        selectedEvents: {},
        exhibitorCompanyName: '',
        exhibitorCompanyInvoice: '',
        exhibitorCompanyType: '',
        exhibitorCompanyAddress: '',
        exhibitorCompanyCountry: '',
        exhibitorCompanyDescription: '',
        exhibitorParticipantName: '',
        exhibitorParticipantSurname: '',
        exhibitorParticipantContact: '',
        exhibitorParticipantJobTitle: '',
        exhibitorParticipantPhone: '',
        exhibitorParticipantEmail: '',
        buyerCompanyName: '',
        buyerCompanyType: '',
        buyerCompanyAddress: '',
        buyerCompanyCountry: '',
        buyerPersonName: '',
        buyerPersonSurname: '',
        buyerPersonContact: '',
        buyerPersonJobTitle: '',
        buyerPersonPhone: '',
        buyerPersonEmail: '',
        buyerPersonCovid: '',
        buyerPersonCovidVac: '',
        isSecondPerson: false,
        secondPersonName: '',
        secondPersonSurname: '',
        secondPersonContact: '',
        secondPersonJobTitle: '',
        secondPersonPhone: '',
        secondPersonEmail: '',
        secondPersonCovid: '',
        secondPersonCovidVac: ''
    },
    isRecaptchaVerified: false
};

const registration = createSlice({
    name: 'registration',
    initialState,
    reducers: {
        removeSelectedEvent: (state, action) => {
            const { [action.payload]: omit, ...obj } = state.formData.selectedEvents;
            state.formData.selectedEvents = obj;
        },
        resetForm: (state) => {
            return initialState
        },
        setData: (state, action) => {
            const { value, path } = action.payload;
            set(state, path, value);
        },
        setSelectedEvent: (state, action) => {
            state.formData.selectedEvents[action.payload.id] = action.payload;
        },
        setRecaptchaVerified: (state, action: PayloadAction<boolean>) => {
            state.isRecaptchaVerified = action.payload;
        }
    }
});

export default registration.reducer;
export const {
    removeSelectedEvent,
    resetForm,
    setData,
    setSelectedEvent,
    setRecaptchaVerified
} = registration.actions;

