import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { set } from 'lodash';
import { InitialState, AlbumCreateForm, SetDataPayload } from "./interfaces";

const initialState: InitialState = {
    formData: {
        title: '',
        photos: []
    },
    openGallery: false,
    galleryIndex: 1
};

const albumCreateForm = createSlice({
    name: 'albumCreateForm',
    initialState,
    reducers: {
        resetForm: (state) => {
            return initialState;
        },
        setData: (state, action: PayloadAction<SetDataPayload>) => {
            const { value, path } = action.payload;
            set(state!, path, value);
        },
        setDefaultFormData: (state, action: PayloadAction<AlbumCreateForm>) => {
            state.formData = action.payload;
        },
        setOpenGallery: (state, action: PayloadAction<boolean>) => {
            state.openGallery = action.payload;
        },
        setGalleryIndex: (state, action: PayloadAction<number>) => {
            state.galleryIndex = action.payload;
        }
    }
});

export default albumCreateForm.reducer;
export const { resetForm, setData, setDefaultFormData, setGalleryIndex, setOpenGallery } = albumCreateForm.actions