import { createSlice } from "@reduxjs/toolkit";
import { set } from 'lodash';
import { InitialState } from "./interfaces";

const initialState: InitialState = {
    mainEvent: null
};

const mainEvent = createSlice({
    name: 'editEvent',
    initialState,
    reducers: {
        resetForm: (state) => {
            state = initialState;
        },
        setData: (state, action) => {
            const { value, path } = action.payload;
            set(state, path, value);
        },
        setMainEvent: (state, action) => {state.mainEvent = action.payload}
    }
});

export default mainEvent.reducer;
export const { resetForm, setData, setMainEvent } = mainEvent.actions;