import type { CustomFlowbiteTheme } from 'flowbite-react';

export const ButtonTheme: CustomFlowbiteTheme = {
    button: {
        base: "group flex items-stretch items-center justify-center bg-cover bg-no-repeat bg-origin-border transition-background duration-200 text-center font-bold relative focus:z-10 focus:outline-none",
        color: {
            gray: "text-black uppercase bg-gray-300 border border-gray-200 enabled:hover:text-cyan-700 enabled:hover:bg-gray-200 enabled:active:bg-gray-400 :ring-cyan-700 focus:text-cyan-700 focus:ring-2",
            yellow: "text-white uppercase bg-main-yellow enabled:hover:bg-light-yellow enabled:active:bg-dark-yellow :ring-cyan-700 focus:text-cyan-700 focus:ring-white",
            warning: "text-alert-color uppercase bg-alert-bg enabled:hover:bg-alert-light-bg enabled:active:bg-alert-dark-bg :ring-cyan-700 focus:text-cyan-700 focus:ring-white"
        },
        pill: {
            off: "rounded-[3px]"
        }
    }
};