import { Album, Contact, EventData, Organizer, RegData } from "../models/interfaces";

const sortListByField = (arr: Contact[] | EventData[] | RegData[] | Organizer[] | Album[], fieldName: string): any[] => {
    return [...arr].sort((a, b) => {
      if (a[fieldName]! < b[fieldName]!) {
        return -1;
      }
      if (a[fieldName]! > b[fieldName]!) {
        return 1;
      }
      return 0;
    });
};

export default sortListByField;