import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { InitialState } from "./interfaces";
import { User } from "../../../../models/interfaces";
import { RegData } from "../../../../models/interfaces";

const initialState: InitialState = {
    targetUser: {
        id: 0,
        username: '',
        role: '',
        firstName: '',
        lastName: '',
        company: '',
        email: '',
        phone: '',
        photo: '',
        isChecked: false
    },
    targetUserRegs: [],
    regsSortedBy: '',
    loadingState: 'idle'
};

const userView = createSlice({
    name: 'userView',
    initialState,
    reducers: {
        resetTargetUser: (state) => {
            return initialState;
        },
        setAllTargetUserRegsChecked: (state, action: PayloadAction<boolean>) => {
            state.targetUserRegs.forEach((reg) => reg.isChecked = action.payload)
        },
        setTargetUserRegIsChecked: (state, action: PayloadAction<number>) => {
            state.targetUserRegs[action.payload].isChecked = !state.targetUserRegs[action.payload].isChecked
        },
        setTargetUserRegsSortedBy: (state, action) => {
            state.regsSortedBy = action.payload
        },
        setTargetUser: (state, action: PayloadAction<User>) => {
            state.targetUser = action.payload
        },
        setTargetUserRegs: (state, action: PayloadAction<RegData[]>) => {
            state.targetUserRegs = action.payload
        },
        setTargetUserRegsState: (state, action: PayloadAction<string>) => {
            state.loadingState = action.payload
        }
    }
});

export default userView.reducer;
export const {
    resetTargetUser,
    setAllTargetUserRegsChecked,
    setTargetUserRegIsChecked,
    setTargetUserRegsSortedBy,
    setTargetUser,
    setTargetUserRegs,
    setTargetUserRegsState
} = userView.actions;