import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RegData } from "../../../../models/interfaces";
import { InitialState, ImageObject } from "./interfaces";

const initialState: InitialState = {
    targetReg: null,
    exhibitorCompanyPhotos: [],
    openGallery: '',
    galleryIndex: 1
};

const registrationPage = createSlice({
    name: 'registrationPage',
    initialState,
    reducers: {
        setTargetReg: (state, action: PayloadAction<RegData | null>) => {
            if (action.payload) {
                state.targetReg = action.payload;
            } else {
                state.targetReg = null
            }
        },
        setRegImages: (state, action: PayloadAction<ImageObject[]>) => {
            state.exhibitorCompanyPhotos = action.payload;
        },
        setOpenGallery: (state, action: PayloadAction<string>) => {
            state.openGallery = action.payload;
        },
        setGalleryIndex: (state, action: PayloadAction<number>) => {
            state.galleryIndex = action.payload;
        }
    }
});

export default registrationPage.reducer;
export const { setTargetReg, setRegImages, setOpenGallery, setGalleryIndex } = registrationPage.actions;