import { createAsyncThunk, createEntityAdapter, createSlice, PayloadAction } from "@reduxjs/toolkit";

// Интерфейсы
import { EventData } from "../../../models/interfaces";
import { RootState } from "../../../store";
import { _apiBase } from "../../../hooks/useHttp";

// Работа с сервером
import { deleteRequest, getRequest, postRequest, putRequest } from "../../../hooks/useHttp";

// Вспомогательные компоненты
import sortListByField from "../../../services/sortList";
import { useAppSelector } from "../../../hooks/state";

export const eventsListAdapter = createEntityAdapter<EventData>();

const initialState = eventsListAdapter.getInitialState({
    eventsSortedBy: 'id',
    eventsLoadingState: 'idle'
});

export const createEvent = createAsyncThunk<void, EventData>(
    'eventsList/createEvent',
    async (data: EventData) => {
        return await postRequest(`${_apiBase}events/`, data, {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('access_token')}`
        });
    }
);

export const deleteEvent = createAsyncThunk<void, number[]>(
    'eventsList/deleteEvent',
    async (eventIds) => {
        const response = await deleteRequest(`${_apiBase}events/`, { event_ids: eventIds}, {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('access_token')}`
        });
        return response;
    }
);

export const editEvent = createAsyncThunk<EventData, EventData>(
    'eventsList/editEvent',
    async (data) => {
        const response = await putRequest(`${_apiBase}events/${data.id}/`, data, {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('access_token')}`
        });
        return response;
    }
);

export const getEvent = createAsyncThunk<EventData, number>(
    'eventsList/getEvent',
    async (eventId) => {
        return await getRequest(`${_apiBase}events/${eventId}/`, { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` });
    }
);

export const getAllEvents = createAsyncThunk(
    'eventsList/getAllEvents',
    async () => {
        const response = await getRequest(`${_apiBase}events/`);
        
        if (!response.message) {
            response.forEach((event: EventData) => {
                event.isChecked = false;
            });

            return response as EventData[];
        };

        return null as null;
    }
);

export const sortEvents = createAsyncThunk<EventData[], string>(
    'eventsList/sortEvents',
    async (field) => {
        const currentEvents = useAppSelector(selectAll);

        const sortedEvents = sortListByField(currentEvents as EventData[], field);

        return sortedEvents as EventData[];
    }
);

const eventsList = createSlice({
    name: 'eventsList',
    initialState,
    reducers: {
        setEventsListSortedBy: (state, action: PayloadAction<string>) => {
            state.eventsSortedBy = action.payload;
        },
        setAllEventsChecked: (state, action: PayloadAction<boolean>) => {
            const updatedEvents = state.ids.map(id => {
                const event = state.entities[id];
                return {
                  ...event,
                  isChecked: action.payload
                };
            });

            eventsListAdapter.upsertMany(state, updatedEvents);
        },
        toggleEventIsChecked: (state, action: PayloadAction<number>) => {
            const event = state.entities[action.payload];
            const updatedEvent = {
                ...event,
                isChecked: !event.isChecked
            };

            eventsListAdapter.upsertOne(state, updatedEvent);
        }
    },
    extraReducers: (builder) => {
        builder
        .addCase(createEvent.pending, state => {state.eventsLoadingState = 'creating'})
        .addCase(createEvent.fulfilled, state => {state.eventsLoadingState = 'idle'})
        .addCase(createEvent.rejected, state => {state.eventsLoadingState = 'error'})
        .addCase(deleteEvent.pending, state => {state.eventsLoadingState = 'deleting'})
        .addCase(deleteEvent.fulfilled, state => {state.eventsLoadingState = 'idle'})
        .addCase(deleteEvent.rejected, state => {state.eventsLoadingState = 'error'})
        .addCase(editEvent.pending, state => {state.eventsLoadingState = 'editing'})
        .addCase(editEvent.fulfilled, state => {state.eventsLoadingState = 'idle'})
        .addCase(editEvent.rejected, state => {state.eventsLoadingState = 'error'})
        .addCase(getAllEvents.pending, state => {state.eventsLoadingState = 'loading'})
        .addCase(getAllEvents.fulfilled, (state, action: PayloadAction<EventData | EventData[] | null>) => {
            state.eventsLoadingState = 'idle';
            if (action.payload) {
                const data = sortListByField(Array.isArray(action.payload) ? action.payload : [action.payload], state.eventsSortedBy)
                eventsListAdapter.setAll(state, data as EventData[]);
            }
        })
        .addCase(getAllEvents.rejected, state => {
            state.eventsLoadingState = 'idle';
            console.log('Events loading error or there are no events');
            eventsListAdapter.setAll(state, [] as EventData[]);
        })
        .addDefaultCase(() => {})
    }
});

export default eventsList.reducer;

export const { selectAll, selectById } = eventsListAdapter.getSelectors<RootState>(state => state.eventsList)

export const {
    setEventsListSortedBy,
    setAllEventsChecked,
    toggleEventIsChecked
} = eventsList.actions;