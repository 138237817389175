import type { CustomFlowbiteTheme } from 'flowbite-react';

export const CheckboxTheme: CustomFlowbiteTheme = {
    checkbox: {
        root: {
            color: {
                default: "focus:ring-main-yellow dark:ring-offset-main-yellow dark:focus:ring-main-yellow text-main-yellow"
            }
        }
    }
};