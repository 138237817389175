import { createSlice } from "@reduxjs/toolkit";
import { InitialState } from "./interfaces";

const initialState: InitialState = {
    isOpen: false
};

const userAddModal = createSlice({
    name: 'userAddModal',
    initialState,
    reducers: {
        openModal: (state) => {
            state.isOpen = true;
        },
        closeModal: (state) => {
            state.isOpen = false;
        }
    }
});

export default userAddModal.reducer;
export const {
    openModal,
    closeModal
} = userAddModal.actions;