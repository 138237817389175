import type { CustomFlowbiteTheme } from 'flowbite-react';

export const SidebarTheme: CustomFlowbiteTheme = {
    sidebar: {
        root: {
            inner: "h-full overflow-y-auto overflow-x-hidden rounded py-4 px-3"
        },
        item: {
            base: "flex items-center justify-center rounded-[3px] p-2 text-base font-normal transition-background duration-200 text-gray-900 hover:bg-gray-200"
        }
    }
};