import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { set } from 'lodash';
import { InitialState, SetDataPayload } from "./interfaces";
import { Contact } from "../../../models/interfaces";

const initialState: InitialState = {
    formData: {
        subdivision: '',
        position: '',
        email: '',
        phone: ''
    }
};

const contactEditForm = createSlice({
    name: 'contactEditForm',
    initialState,
    reducers: {
        resetForm: (state) => {
            return initialState;
        },
        setData: (state, action: PayloadAction<SetDataPayload>) => {
            const { value, path } = action.payload;
            set(state!, path, value);
        },
        setDefaultFormData: (state, action: PayloadAction<Contact>) => {
            state.formData = action.payload;
        }
    }
});

export default contactEditForm.reducer;
export const { resetForm, setData, setDefaultFormData } = contactEditForm.actions