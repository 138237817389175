import axios from "axios";
import getCookie from "../services/getCookie";
import { useAppDispatch } from "./state";
import { setProcess } from "../components/app/processSlice";
import { Headers } from "../models/interfaces";

import toast from "react-hot-toast";

const useHttp = () => {
    const dispatch = useAppDispatch();

    // Выгрузка CSRF токена из куки
    const csrfToken = getCookie('csrftoken');

    // Настройки axios
    if (csrfToken) {
        axios.defaults.headers.common['X-CSRFToken'] = csrfToken;
    }
    axios.defaults.withCredentials = true;

    // Отправка GET запросов
    const getRequest = async (url: string, headers?: Headers) => {
        try {
            dispatch(setProcess("sending"));
            const response = await axios.get(url, { headers: headers });

            if (response.status !== 200) {
                console.log(`Could not fetch ${url} (GET), status: ${response.status}`);
                toast('Упс. Ошибка при отправке запроса. Пожалуйста, повторите попытку.', {
                    position: 'bottom-right',
                    icon: '🤔'
                });
            };

            dispatch(setProcess('idle'));
            return response.data;
        } catch (error) {
            dispatch(setProcess("error"));
            console.log(error);
        };
    };

    // Отправка POST запросов
    const postRequest = async <T>(url: string, data: Record<string, any>, headers: Headers): Promise<T> => {
        try {
            const response = await axios.post(url, data, { headers });

            if (response.status !== 200 && response.status !== 201) {
                throw new Error(`Could not fetch ${url} (POST), status: ${response.status}`);
            };

            return response.data;
        } catch (error) {
            throw error;
        };
    };

    // Отправка PUT запросов
    const putRequest = async (url: string, data: Record<string, any>, headers: Headers) => {
        try {
            dispatch(setProcess("sending"));
            const response = await axios.put(url, data, { headers });

            if (response.status !== 200 && response.status !== 201) {
                throw new Error(`Could not fetch ${url} (PUT), status: ${response.status}`);
            };

            dispatch(setProcess('idle'));
            return response.data;
        } catch (error) {
            dispatch(setProcess("error"));
            throw error;
        };
    };

    // Отправка DELETE запросов
    const deleteRequest = async (url: string, data: Record<string, any>, headers: Headers) => {
        try {
            dispatch(setProcess("sending"));
            const response = await axios.delete(url, { headers: headers, data: data });

            if (response.status !== 204) {
                throw new Error(`Could not fetch ${url} (DELETE), status: ${response.status}`);
            };

            dispatch(setProcess('idle'));
            return response.data;
        } catch (error) {
            dispatch(setProcess("error"));
            throw error;
        };
    };

    const clearError = () => {
        dispatch(setProcess('idle'));
    };

    return { getRequest, postRequest, putRequest, deleteRequest, clearError };
};

const _apiBase = 'https://travelseasons.kz/api/';
const _authBase = 'https://travelseasons.kz/auth/';
const accessToken = localStorage.getItem('access_token');
const refreshToken = localStorage.getItem("refresh_token");

export const deleteRequest = async (url: string, data: Record<string, any>, headers: Headers) => {
    try {
        const response = await axios.delete(url, { headers: headers, data: data });

        if (response.status !== 204) {
            throw new Error(`Could not fetch ${url} (DELETE), status: ${response.status}`);
        };

        return response.data;
    } catch (error) {
        throw error;
    };
};


export const postRequest = async (url: string, data: Record<string, any>, headers: Headers) => {
    try {
        const response = await axios.post(url, data, { headers });

        if (response.status !== 200 && response.status !== 201) {
            throw new Error(`Could not fetch ${url} (POST), status: ${response.status}`);
        };

        return response.data;
    } catch (error) {
        throw error;
    };
};

export const getRequest = async (url: string, headers?: Headers) => {
    try {
        const response = await axios.get(url, { headers: headers });

        if (response.status !== 200 && response.status !== 204) {
            console.log(`Could not fetch ${url} (GET), status: ${response.status}`);
            toast('Упс. Ошибка при отправке запроса. Пожалуйста, повторите попытку.', {
                position: 'bottom-right',
                icon: '🤔'
            });
        };

        return response.data;
    } catch (error) {
        console.log(error);
    };
};

export const putRequest = async (url: string, data: Record<string, any>, headers: Headers) => {
    try {
        const response = await axios.put(url, data, { headers });

        if (response.status !== 200 && response.status !== 201) {
            throw new Error(`Could not fetch ${url} (PUT), status: ${response.status}`);
        };

        return response.data;
    } catch (error) {
        throw error;
    };
};

export default useHttp;
export { _apiBase, _authBase, accessToken, refreshToken };