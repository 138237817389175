import { createAsyncThunk, createEntityAdapter, PayloadAction, createSlice } from "@reduxjs/toolkit";
import { User } from "../../../models/interfaces";
import { _apiBase, deleteRequest, getRequest, postRequest } from "../../../hooks/useHttp";
import { RootState } from "../../../store";
import { UserAddForm } from "../userAddForm/interfaces";

export const usersAdapter = createEntityAdapter<User>();

const initialState = usersAdapter.getInitialState({
    usersLoadingState: 'idle',
    filter: 'all'
});

export const getAllUsers = createAsyncThunk<User[]>(
    'users/getAllUsers',
    async () => {
        const response = await getRequest(`${_apiBase}users/`, { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` });

        response.forEach((user: User) => {
            user.isChecked = false;
        });

        return response;
    }
);

export const addUser = createAsyncThunk<void, {userData: UserAddForm, lang: string}>(
    'users/addUser',
    async ({userData, lang}) => {
        const response = await postRequest(`${_apiBase}users/`, {data: userData, lang: lang}, {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('access_token')}`
        });

        return response;
    }
);

export const deleteUser = createAsyncThunk<void, number[]>(
    'users/deleteUser',
    async (userIds) => {
        const response = await deleteRequest(`${_apiBase}users/`, { user_ids: userIds }, { 'Authorization': `Bearer ${localStorage.getItem('access_token')}`, 'Content-Type': 'application/json' });

        return response;
    }
);

const users = createSlice({
    name: 'users',
    initialState,
    reducers: {
        resetUsersList: (state) => {
            return initialState
        },
        toggleUserIsChecked: (state, action: PayloadAction<number>) => {
            const user = state.entities[action.payload];
            const updatedUser = {
                ...user,
                isChecked: !user.isChecked
            };

            usersAdapter.upsertOne(state, updatedUser);
        },
        setFilter: (state, action: PayloadAction<string>) => {
            state.filter = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder
        .addCase(getAllUsers.pending, state => {state.usersLoadingState = 'loading'})
        .addCase(getAllUsers.fulfilled, (state, action) => {
            state.usersLoadingState = 'idle';
            if (action.payload) {
                usersAdapter.setAll(state, action.payload);
            } else {
                usersAdapter.setAll(state, []);
        }})
        .addCase(getAllUsers.rejected, state => {state.usersLoadingState = 'error'})
        .addCase(addUser.pending, state => {state.usersLoadingState = 'adding'})
        .addCase(addUser.fulfilled, state => {state.usersLoadingState = 'idle'})
        .addCase(addUser.rejected, state => {state.usersLoadingState = 'error'})
        .addCase(deleteUser.pending, state => {state.usersLoadingState = 'deleting'})
        .addCase(deleteUser.fulfilled, state => {state.usersLoadingState = 'idle'})
        .addCase(deleteUser.rejected, state => {state.usersLoadingState = 'error'})
        .addDefaultCase(() => {})
    }
});

export default users.reducer;

export const { selectAll } = usersAdapter.getSelectors<RootState>(state => state.users)

export const {
    resetUsersList,
    toggleUserIsChecked,
    setFilter
} = users.actions;