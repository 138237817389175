import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { InitialState, SettingsFormData } from "./interfaces";
import { SetData } from "../../models/interfaces";
import { set } from 'lodash';

const initialState: InitialState = {
    formData: {
        username: '',
        firstName: '',
        lastName: '',
        phone: '',
        email: '',
        company: '',
        currentPassword: '',
        newPassword: ''
    }
};

const userSettings = createSlice({
    name: 'userSettings',
    initialState,
    reducers: {
        setData: (state, action: PayloadAction<SetData>) => {
            const { value, path } = action.payload;
            set(state, path, value);
        },
        setDefaultData: (state, action: PayloadAction<SettingsFormData>) => {
            state.formData = action.payload
        },
        resetForm: () => {
            return initialState;
        }
    }
});

export default userSettings.reducer;
export const { setData, setDefaultData, resetForm } = userSettings.actions;