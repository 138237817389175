import { createSlice } from "@reduxjs/toolkit";
import { ProcessInitialState } from "./interfaces";

const initialState: ProcessInitialState = {
    process: 'idle'
};

const appProcess = createSlice({
    name: 'process',
    initialState,
    reducers: {
        setProcess: (state, action) => {state.process = action.payload}
    }
});

export default appProcess.reducer;
export const { setProcess } = appProcess.actions;