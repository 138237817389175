import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { set } from 'lodash';
import { InitialState, SetDataPayload, UserAddForm } from "./interfaces";

const initialState: InitialState = {
    formData: {
        firstName: '',
        lastName: '',
        username: '',
        email: ''
    }
};

const userAddForm = createSlice({
    name: 'userAddForm',
    initialState,
    reducers: {
        resetForm: (state) => {
            return initialState;
        },
        setData: (state, action: PayloadAction<SetDataPayload>) => {
            const { value, path } = action.payload;
            set(state!, path, value);
        },
        setDefaultFormData: (state, action: PayloadAction<UserAddForm>) => {
            state.formData = action.payload;
        }
    }
});

export default userAddForm.reducer;
export const { resetForm, setData, setDefaultFormData } = userAddForm.actions