import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { AuthInitialState } from "./interfaces";

const initialState: AuthInitialState = {
    isAuthorized: false
};

const auth = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setIsAuthorized: (state, action: PayloadAction<boolean>) => {
            state.isAuthorized = action.payload;
        }
    }
});

export default auth.reducer;
export const { setIsAuthorized } = auth.actions;