import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { set } from 'lodash';
import { InitialState, AlbumEditForm, SetDataPayload } from "./interfaces";

const initialState: InitialState = {
    formData: {
        title: '',
        photos: []
    },
    isCurrentPhotosGalleryOpen: false,
    isNewPhotosGalleryOpen: false,
    galleryIndex: 1
};

const albumEditForm = createSlice({
    name: 'albumEditForm',
    initialState,
    reducers: {
        resetForm: (state) => {
            return initialState;
        },
        setData: (state, action: PayloadAction<SetDataPayload>) => {
            const { value, path } = action.payload;
            set(state!, path, value);
        },
        setDefaultFormData: (state, action: PayloadAction<AlbumEditForm>) => {
            state.formData = action.payload;
        },
        setCurrentPhotosGallery: (state, action: PayloadAction<boolean>) => {
            state.isCurrentPhotosGalleryOpen = action.payload;
        },
        setNewPhotosGallery: (state, action: PayloadAction<boolean>) => {
            state.isNewPhotosGalleryOpen = action.payload;
        },
        setGalleryIndex: (state, action: PayloadAction<number>) => {
            state.galleryIndex = action.payload;
        },
    }
});

export default albumEditForm.reducer;
export const {
    resetForm,
    setData,
    setDefaultFormData,
    setGalleryIndex,
    setCurrentPhotosGallery,
    setNewPhotosGallery
} = albumEditForm.actions