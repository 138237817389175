import { createSlice } from "@reduxjs/toolkit";
import { InitialState } from "./interfaces";

const initialState: InitialState = {
    isOpen: false
};

const organizerCreateModal = createSlice({
    name: 'organizerCreateModal',
    initialState,
    reducers: {
        openCreateModal: (state) => {
            state.isOpen = true;
        },
        closeCreateModal: (state) => {
            state.isOpen = false;
        }
    }
});

export default organizerCreateModal.reducer;
export const {
    openCreateModal,
    closeCreateModal
} = organizerCreateModal.actions;