import type { CustomFlowbiteTheme } from 'flowbite-react';

export const DatePickerTheme: CustomFlowbiteTheme = {
    datepicker: {
        root: {
            base: "relative"
        },
        popup: {
            root: {
                base: "absolute top-10 z-50 block pt-2",
                inline: "relative top-0 z-auto",
                inner: "inline-block rounded-[3px] bg-white p-4 shadow-lg"
            },
            header: {
                title: "px-2 py-3 text-center font-semibold text-gray-900",
                selectors: {
                    base: "flex justify-between mb-2",
                    button: {
                        base: "text-sm rounded-[3px] text-gray-900 bg-white font-semibold py-2.5 px-5 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 view-switch",
                    }
                }
            },
            view: {
                base: "p-1"
            },
            footer: {
                base: "flex mt-2 space-x-2",
                button: {
                    base: "w-full rounded-[3px] px-5 py-2 text-center text-sm font-medium focus:ring-4 focus:ring-cyan-300",
                    today: "bg-main-yellow text-white hover:opacity-[0.5]",
                    clear: "border border-gray-300 bg-white text-gray-900 hover:bg-gray-100"
                }
            }
        },
        views: {
            days: {
                header: {
                    base: "grid grid-cols-7 mb-1",
                    title: "dow h-6 text-center text-sm font-medium leading-6 text-gray-500"
                },
                items: {
                    base: "grid w-64 grid-cols-7",
                    item: {
                        base: "block flex-1 cursor-pointer rounded-[3px] border-0 text-center text-sm font-semibold leading-9 text-gray-900 hover:opacity-[0.5]",
                        selected: "bg-main-yellow text-white hover:opacity-[0.5]",
                        disabled: "text-gray-500"
                    }
                }
            },
            months: {
                items: {
                    base: "grid w-64 grid-cols-4",
                    item: {
                        base: "block flex-1 cursor-pointer rounded-[3px] border-0 text-center text-sm font-semibold leading-9 text-gray-900 hover:opacity-[0.5]",
                        selected: "bg-main-yellow text-white hover:opacity-[0.5]",
                        disabled: "text-gray-500"
                    }
                }
            },
            years: {
                items: {
                    base: "grid w-64 grid-cols-4",
                    item: {
                        base: "block flex-1 cursor-pointer rounded-[3px] border-0 text-center text-sm font-semibold leading-9 hover:opacity-[0.5] text-gray-900",
                        selected: "bg-main-yellow text-white hover:opacity-[0.5]",
                        disabled: "text-gray-500"
                    }
                }
            },
            decades: {
                items: {
                    base: "grid w-64 grid-cols-4",
                    item: {
                        base: "block flex-1 cursor-pointer rounded-[3px] border-0 text-center text-sm font-semibold leading-9  hover:opacity-[0.5] text-gray-900",
                        selected: "bg-main-yellow text-white hover:opacity-[0.5]",
                        disabled: "text-gray-500"
                    }
                }
            }
        }
    }
};