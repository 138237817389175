import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { InitialState } from "./interfaces";

const initialState: InitialState = {
    album: false,
    albumForm: false,
    contact: false,
    contactForm: false,
    events: false,
    organizer: false,
    organizerForm: false,
    registrations: false,
    users: false
};

const deleteModal = createSlice({
    name: 'deleteModal',
    initialState,
    reducers: {
        setAlbumDeleteModal: (state, action: PayloadAction<boolean>) => {
            state.album = action.payload;
        },
        setAlbumFormDeleteModal: (state, action: PayloadAction<boolean>) => {
            state.albumForm = action.payload;
        },
        setContactDeleteModal: (state, action: PayloadAction<boolean>) => {
            state.contact = action.payload;
        },
        setContactFormDeleteModal: (state, action: PayloadAction<boolean>) => {
            state.contactForm = action.payload;
        },
        setEventDeleteModal: (state, action: PayloadAction<boolean>) => {
            state.events = action.payload;
        },
        setOrganizerDeleteModal: (state, action: PayloadAction<boolean>) => {
            state.organizer = action.payload;
        },
        setOrganizerFormDeleteModal: (state, action: PayloadAction<boolean>) => {
            state.organizerForm = action.payload;
        },
        setRegDeleteModal: (state, action: PayloadAction<boolean>) => {
            state.registrations = action.payload;
        },
        setUserDeleteModal: (state, action: PayloadAction<boolean>) => {
            state.users = action.payload;
        },
        closeDeleteModal: (state) => {
            return initialState;
        }
    }
});

export default deleteModal.reducer;
export const {
    setAlbumDeleteModal,
    setAlbumFormDeleteModal,
    setContactDeleteModal,
    setContactFormDeleteModal,
    setEventDeleteModal,
    setOrganizerDeleteModal,
    setOrganizerFormDeleteModal,
    setRegDeleteModal,
    setUserDeleteModal,
    closeDeleteModal
} = deleteModal.actions;