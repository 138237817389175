import { createSlice } from "@reduxjs/toolkit";
import { InitialState } from "./interfaces";

const initialState: InitialState = {
    isOpen: false
};

const albumCreateModal = createSlice({
    name: 'albumCreateModal',
    initialState,
    reducers: {
        openCreateModal: (state) => {
            state.isOpen = true;
        },
        closeCreateModal: (state) => {
            state.isOpen = false;
        }
    }
});

export default albumCreateModal.reducer;
export const {
    openCreateModal,
    closeCreateModal
} = albumCreateModal.actions;