import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { InitialState } from "./interfaces";

const initialState: InitialState = {
    isEventGalleryOpen: false,
    selectedPhotoIndex: NaN,
    selectedEvent: ''
};

const eventGallery = createSlice({
    name: 'eventGallery',
    initialState,
    reducers: {
        setIsEventGalleryOpen: (state, action: PayloadAction<boolean>) => {
            state.isEventGalleryOpen = action.payload;
        },
        setIndex: (state, action: PayloadAction<number>) => {
            state.selectedPhotoIndex = action.payload;
        },
        setSelectedEvent: (state, action: PayloadAction<string>) => {
            state.selectedEvent = action.payload;
        },
    }
});

export default eventGallery.reducer;
export const { setIsEventGalleryOpen, setIndex, setSelectedEvent } = eventGallery.actions;