// Хуки
import useHttp from "./useHttp";

// Redux
import { useAppDispatch } from "./state";
import { resetCurrentUser, setCurrentUser, setCurrentUserRegsState } from "../pages/layouts/user/userSlice";
import { setTargetUser, setTargetUserRegsState } from "../pages/layouts/admin/user/userViewSlice";
import { setIsAuthorized } from "../components/app/authSlice";
import { setMainEvent } from "../components/mainEvent/mainEventSlice";

// Интерфейсы
import { AuthData, Headers, RegData, RequestRegData, User } from "../models/interfaces";
import { TokenResponse } from "./interfaces";
import { SettingsFormData } from "../components/userSettingsForm/interfaces";

// Уведомления
import toast from "react-hot-toast";

const useAPI = () => {
    const _apiBase = 'https://travelseasons.kz/api/';
    const _authBase = 'https://travelseasons.kz/auth/';
    const accessToken = localStorage.getItem('access_token');
    const refreshToken = localStorage.getItem("refresh_token");

    const { getRequest, postRequest, putRequest, deleteRequest } = useHttp();

    const dispatch = useAppDispatch();

    // Авторизация
    const authorize = async (data: AuthData) => {
        const response: TokenResponse = await postRequest(`${_authBase}login/`, data, {
            "Content-Type": "application/json",
            "Accept": "application/json"
        });

        if (data.remember) localStorage.setItem('rememberMe', 'true');

        const { access, refresh } = response;

        localStorage.setItem("access_token", access);
        localStorage.setItem("refresh_token", refresh);
        dispatch(setIsAuthorized(true));
        getUserInfo(access);
    };

    // Меняем данные пользователя
    const changeUserInfo = async (data: SettingsFormData) => {
        const response = await putRequest(`${_apiBase}user/`, data, {
            'Authorization': `Bearer ${accessToken}`,
            "Content-Type": "multipart/form-data",
            "Accept": "application/json"
        });

        return response;
    };

    // Проверка авторизации
    const checkAuthorization = () => {
        if (localStorage.getItem('refresh_token') && localStorage.getItem('rememberMe')) {
            getNewTokens()
            .then((access) => {
                getUserInfo(access)
                    .then((data) => {
                        if (data) {
                            dispatch(setIsAuthorized(true));
                        };
                    })
                    .catch((e) => {
                        toast('Ошибка при загрузке данных о пользователе. Пожалуйста, обновите страницу.', {
                            position: 'bottom-right',
                            icon: '😰'
                        });
                    });
            })
            .catch((e) => {
                console.log("Token refresh error", e.response?.data);
                toast('Ошибка при обновлении данных авторизации. Пожалуйста, обновите страницу.', {
                    position: 'bottom-right',
                    icon: '😰'
                });
            });
        } else {
            dispatch(setCurrentUser({id: 0,
                username: '',
                role: 'anon',
                firstName: '',
                lastName: '',
                company: '',
                email: '',
                phone: '',
                photo: '',
                isChecked: false}))
        };
    };

    // Удаление регистрации
    const deleteRegData = async (checkedRegs: RegData[]) => {
        const regIds = checkedRegs.map(reg => reg.id);

        await deleteRequest(`${_apiBase}registrations/`, { reg_ids: regIds }, { 'Authorization': `Bearer ${accessToken}`, 'Content-Type': 'application/json' });
    };

    // Удаление пользователя
    const deleteUser = async (checkedUsers: User[]) => {
        const userIds = checkedUsers.map(user => user.id);

        await deleteRequest(`${_apiBase}users/`, { user_ids: userIds }, { 'Authorization': `Bearer ${accessToken}`, 'Content-Type': 'application/json' });
    };

    // Запрос списка регистрации по мероприятию
    const getEventRegistrations = async (eventId: string) => {
        const response = await getRequest(`${_apiBase}events/${eventId}/registrations/`, { 'Authorization': `Bearer ${accessToken}` });

        response.forEach((reg: RegData) => {
            reg.isChecked = false;
        });

        return response;
    };

    // Запрос изображений по регистрации участника
    const getExhibitorCompanyPhotos = async (id: number) => {
        const response = await getRequest(`${_apiBase}exhibitor-company-photos/${id}/`, { 'Authorization': `Bearer ${accessToken}` });

        return response;
    };

    // Запрос информации о главном мероприятий
    const getMainEvent = async () => {
        const response = await getRequest(`${_apiBase}main-event/`);
        if (response.message === 'There is no main event') {
            dispatch(setMainEvent({ message: "Главное мероприятие не выбрано" }))
        } else {
            dispatch(setMainEvent(response));
        };
    };

    // Запрос токенов
    const getNewTokens = async (): Promise<string> => {
        localStorage.removeItem("access_token");

        const response = await postRequest<TokenResponse>(`${_authBase}refresh/`, {
            refresh_token: refreshToken
        }, {
            "Content-Type": "application/json",
            "Accept": "application/json"
        });

        const { access, refresh } = response;

        localStorage.setItem("access_token", access);
        localStorage.setItem("refresh_token", refresh);

        return access;
    };

    // Запрос политики конфиденциальности
    const getPolicy = async (title: string) => {
        if (accessToken) {
            const response = await getRequest(`${_apiBase}get-policy/${title}/`, { 'Authorization': `Bearer ${accessToken}` });

            return response;
        } else {
            const response = await getRequest(`${_apiBase}get-policy/${title}/`);

            return response;
        }
    };

    // Запрос информации о пользователе
    const getUserInfo = async (token?: string) => {
        const response = await getRequest(`${_apiBase}user/`, { 'Authorization': `Bearer ${token || accessToken}` });
        dispatch(setCurrentUser(response));

        return response;
    };

    // Запрос информации о пользователе
    const getUserInfoByUsername = async (username: string) => {
        const response = await getRequest(`${_apiBase}user/${username}`, { 'Authorization': `Bearer ${accessToken}` });
        dispatch(setTargetUser(response));

        return response;
    };

    // Запрос регистрации пользователя
    const getUserRegistrations = async (id?: number) => {
        if (id) {
            dispatch(setTargetUserRegsState('loading'))
            const response = await getRequest(`${_apiBase}registrations-of-user/${id}`, { 'Authorization': `Bearer ${accessToken}` });

            response.forEach((reg: RegData) => {
                reg.isChecked = false;
            });

            dispatch(setTargetUserRegsState('ready'));
            return response as RegData[];
        } else {
            const response = await getRequest(`${_apiBase}registrations-of-user/`, { 'Authorization': `Bearer ${accessToken}` });

            response.forEach((reg: RegData) => {
                reg.isChecked = false;
            });

            dispatch(setCurrentUserRegsState('ready'));
            return response as RegData[];
        };
    };

    // Запрос информации о регистрации
    const getRegData = async (regId: string | undefined) => {
        if (regId) {
            const response = await getRequest(`${_apiBase}registrations/${regId}/`, { 'Authorization': `Bearer ${accessToken}` });
            return response;
        } else {
            const response = await getRequest(`${_apiBase}registrations/`, { 'Authorization': `Bearer ${accessToken}` });
            return response;
        };
    };

    // Выход из аккаунта
    const logout = () => {
        localStorage.removeItem('access_token');
        localStorage.removeItem('refresh_token');
        localStorage.removeItem('rememberMe');
        dispatch(resetCurrentUser());
        dispatch(setIsAuthorized(false));
    };

    // Отправка данных с формы регистрации на мероприятие
    const sendRegData = async (data: RequestRegData, headers: Headers) => {
        const response = await putRequest(`${_apiBase}new-registration/`, { ...data, lang: localStorage.getItem('lang') }, headers);

        return response;
    };

    // Отправка данных для изменения
    const sendResetData = async (data: { password: string }, uuidb64: string, token: string) => {
        const response: TokenResponse = await postRequest(`${_authBase}reset-password/${uuidb64}/${token}/`, data, {
            "Content-Type": "application/json",
            "Accept": "application/json"
        });

        return response;
    };

    // Отправка запроса для восстановления пароля
    const sendResetRequest = async (data: { email: string }) => {
        const response = await postRequest(`${_authBase}request-reset/`, { email: data.email, lang: localStorage.getItem('lang') }, {
            "Content-Type": "application/json",
            "Accept": "application/json"
        });

        return response;
    };

    return {
        authorize,
        changeUserInfo,
        checkAuthorization,
        deleteRegData,
        deleteUser,
        getEventRegistrations,
        getExhibitorCompanyPhotos,
        getMainEvent,
        getNewTokens,
        getPolicy,
        getRegData,
        getUserInfo,
        getUserInfoByUsername,
        getUserRegistrations,
        logout,
        sendRegData,
        sendResetData,
        sendResetRequest
    };
};

export default useAPI;