// Хуки
import useAPI from "../../hooks/useApi";
import { useNavigate } from "react-router-dom";

// Стилистические импорты
import { Avatar, Dropdown } from "flowbite-react";

// Redux
import { useAppDispatch, useAppSelector } from "../../hooks/state";
import { setIsHamburgerToggled } from "./headerSlice";

// Файлы локализации
import getLocale from "../../services/getLocale";
import ru from './locale/ru.json';
import kk from './locale/kk.json';
import en from './locale/en.json';

const ProfileButton = () => {
  const { logout } = useAPI();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const userInfo = useAppSelector(state => state.user.currentUser);
  const { id, role, firstName, lastName, email, photo } = userInfo;

  // Начало блока локализации
  const lang = useAppSelector((state) => state.lang.lang);
  const locale = getLocale(lang, {ru: ru, kk: kk, en: en});
  // Конец блока локализации

  return (
    <div className="flex mr-[20px]">
      <Dropdown arrowIcon={false} inline label={<Avatar alt="User settings" onClick={() => dispatch(setIsHamburgerToggled(false))} img={photo ? photo : 'https://travelseasons.kz/static/assets/images/avatar.svg'} rounded/>}>
        <Dropdown.Header>
          <span className="block text-sm">{firstName + ' ' + lastName}</span>
          <span className="block truncate text-sm font-medium">{email}</span>
        </Dropdown.Header>
        <Dropdown.Item onClick={() => navigate(`/user/${id}`)}>{locale?.dropdowm.profile}</Dropdown.Item>
        {role === 'admin' || role === 'owner' ? <Dropdown.Item onClick={() => navigate(`/admin`)}>{locale?.dropdowm.panel}</Dropdown.Item> : null}
        <Dropdown.Item onClick={() => navigate(`/user/${id}/settings`)}>{locale?.dropdowm.settings}</Dropdown.Item>
        <Dropdown.Divider />
        <Dropdown.Item onClick={() => {
          logout();
          navigate('/');
        }}>{locale?.dropdowm.logout}</Dropdown.Item>
      </Dropdown>
    </div>
  );
};

export default ProfileButton;