import type { CustomFlowbiteTheme } from 'flowbite-react';

export const ContactEditModalTheme: CustomFlowbiteTheme = {
    modal: {
        root: {
            base: "fixed top-0 right-0 left-0 z-50 h-modal h-screen overflow-y-auto overflow-x-hidden md:inset-0 md:h-full",
            show: {
                on: "flex bg-[#000000] bg-opacity-20"
            }
        },
        content: {
            base: "relative max-w-5xl w-full flex justify-center p-8 md:h-auto desktop:max-w-5xl",
            inner: "relative rounded-[3px] bg-white shadow flex flex-col max-h-[90vh] max-w-[500px] w-[100%]"
        },
        body: {
            base: "p-6 flex-1 overflow-auto bg-background-accent"
        },
        header: {
            base: "flex items-start justify-between bg-gray-100 rounded-t-[3px] p-5 ",
            close: {
                base: "ml-auto rounded inline-flex items-center bg-transparent p-1.5 text-sm text-gray-400 transition duration-750 hover:bg-gray-200 hover:text-gray-900"
            }
        }
    }
};