import { createAsyncThunk, createEntityAdapter, PayloadAction, createSlice, EntityId } from "@reduxjs/toolkit";
import { Organizer } from "../../../models/interfaces";
import { _apiBase, deleteRequest, getRequest, postRequest, putRequest } from "../../../hooks/useHttp";
import sortListByField from "../../../services/sortList";
import { RootState } from "../../../store";
import { useAppSelector } from "../../../hooks/state";
import { OrganizerEditForm } from "../organizerEditForm/interfaces";

export const organizersAdapter = createEntityAdapter<Organizer>();

const initialState = organizersAdapter.getInitialState({
    organizersSortedBy: 'id',
    organizersLoadingState: 'idle'
});

export const getAllOrganizers = createAsyncThunk<Organizer[] | null>(
    'organizers/getAllOrganizers',
    async () => {
        const response = await getRequest(`${_apiBase}organizers/`);

        if (!response.message) {
            response.forEach((organizer: Organizer) => {
                organizer.isChecked = false;
            });

            return response as Organizer[];
        };

        return null as null;
    }
);

export const sortOrganizers = createAsyncThunk<Organizer[], string>(
    'organizers/sortOrganizers',
    async (field) => {
        const currentOrganizers = useAppSelector(selectAll);

        const sortedOrganizers = sortListByField(currentOrganizers as Organizer[], field);

        return sortedOrganizers as Organizer[];
    }
);


export const deleteOrganizer = createAsyncThunk<void, EntityId[]>(
    'organizers/deleteOrganizer',
    async (organizerIds) => {
        return await deleteRequest(`${_apiBase}organizers/`, { organizerIds: organizerIds }, {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('access_token')}`
        });
    }
);

export const editOrganizer = createAsyncThunk<Organizer, { organizerId: number, data: OrganizerEditForm }>(
    'organizers/editOrganizer',
    async (arg) => {
        return await putRequest(`${_apiBase}organizers/`, {organizerId: arg.organizerId, data: arg.data}, {
            "Content-Type": "multipart/form-data",
            "Accept": "application/json",
            'Authorization': `Bearer ${localStorage.getItem('access_token')}`
        });
    }
);

export const createOrganizer = createAsyncThunk<void, OrganizerEditForm>(
    'organizers/createOrganizer',
    async (data: OrganizerEditForm) => {
        return await postRequest(`${_apiBase}organizers/`, data, {
            "Content-Type": "multipart/form-data",
            "Accept": "application/json",
            'Authorization': `Bearer ${localStorage.getItem('access_token')}`
        });
    }
);

const organizers = createSlice({
    name: 'organizers',
    initialState,
    reducers: {
        setAllOrganizersChecked: (state, action: PayloadAction<boolean>) => {
            const updatedOrganizers = state.ids.map(id => {
                const organizer = state.entities[id];
                return {
                  ...organizer,
                  isChecked: action.payload
                };
            });

            organizersAdapter.upsertMany(state, updatedOrganizers);
        },
        setOrganizersListSortedBy: (state, action: PayloadAction<string>) => {
            state.organizersSortedBy = action.payload;
        },
        toggleOrganizerIsChecked: (state, action: PayloadAction<number>) => {
            const organizer = state.entities[action.payload];
            const updatedOrganizer = {
                ...organizer,
                isChecked: !organizer.isChecked
            };

            organizersAdapter.upsertOne(state, updatedOrganizer);
        }
    },
    extraReducers: (builder) => {
        builder
        .addCase(getAllOrganizers.pending, state => {state.organizersLoadingState = 'loading'})
        .addCase(getAllOrganizers.fulfilled, (state, action) => {
            state.organizersLoadingState = 'idle';
            if (action.payload) {
                const data = sortListByField(Array.isArray(action.payload) ? action.payload : [action.payload], state.organizersSortedBy)
                organizersAdapter.setAll(state, data as Organizer[]);
            } else {
                organizersAdapter.setAll(state, [] as Organizer[]);
            }
        })
        .addCase(getAllOrganizers.rejected, state => {state.organizersLoadingState = 'error'})
        .addCase(sortOrganizers.pending, state => {state.organizersLoadingState = 'sorting'})
        .addCase(sortOrganizers.fulfilled, state => {state.organizersLoadingState = 'idle'})
        .addCase(sortOrganizers.rejected, state => {state.organizersLoadingState = 'error'})
        .addCase(deleteOrganizer.pending, state => {state.organizersLoadingState = 'deleting'})
        .addCase(deleteOrganizer.fulfilled, state => {state.organizersLoadingState = 'idle'})
        .addCase(deleteOrganizer.rejected, state => {state.organizersLoadingState = 'error'})
        .addCase(editOrganizer.pending, state => {state.organizersLoadingState = 'editing'})
        .addCase(editOrganizer.fulfilled, state => {state.organizersLoadingState = 'idle'})
        .addCase(editOrganizer.rejected, state => {state.organizersLoadingState = 'error'})
        .addCase(createOrganizer.pending, state => {state.organizersLoadingState = 'creating'})
        .addCase(createOrganizer.fulfilled, state => {state.organizersLoadingState = 'idle'})
        .addCase(createOrganizer.rejected, state => {state.organizersLoadingState = 'error'})
        .addDefaultCase(() => {})
    }
});

export const { selectAll, selectById } = organizersAdapter.getSelectors<RootState>(state => state.organizers)

export default organizers.reducer;
export const { setAllOrganizersChecked, setOrganizersListSortedBy, toggleOrganizerIsChecked } = organizers.actions;