import type { CustomFlowbiteTheme } from 'flowbite-react';

export const FooterTheme: CustomFlowbiteTheme = {
    footer: {
        root: {
            base: "w-full rounded-lg bg-white shadow"
        },
        copyright: {
            base: "text-sm text-gray-500"
        }
    }
};