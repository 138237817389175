import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { InitialState } from "./interfaces";

const initialState: InitialState = {
    isOpen: false,
    target: null
};

const albumEditModal = createSlice({
    name: 'albumEditModal',
    initialState,
    reducers: {
        setTarget: (state, action: PayloadAction<number | null>) => {
            state.target = action.payload;
            state.isOpen = true;
        },
        closeEditModal: (state) => {
            return initialState;
        }
    }
});

export default albumEditModal.reducer;
export const {
    setTarget,
    closeEditModal
} = albumEditModal.actions;