import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { set } from 'lodash';
import { InitialState, OrganizerEditForm, SetDataPayload } from "./interfaces";

const initialState: InitialState = {
    formData: {
        title: '',
        description: '',
        logo: []
    }
};

const organizerEditForm = createSlice({
    name: 'organizerEditForm',
    initialState,
    reducers: {
        resetForm: (state) => {
            return initialState;
        },
        setData: (state, action: PayloadAction<SetDataPayload>) => {
            const { value, path } = action.payload;
            set(state!, path, value);
        },
        setDefaultFormData: (state, action: PayloadAction<OrganizerEditForm>) => {
            state.formData = action.payload;
        }
    }
});

export default organizerEditForm.reducer;
export const { resetForm, setData, setDefaultFormData } = organizerEditForm.actions