import { createAsyncThunk, createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import { _apiBase, putRequest } from "../../../hooks/useHttp";
import { RootState } from "../../../store";

export const policyEditorAdapter = createEntityAdapter();

const initialState = policyEditorAdapter.getInitialState({
    policyLoadingState: 'idle',
    selectedPolicy: 'ru',
    content: null
});

export const setPolicy = createAsyncThunk<void, {title: string, content: object }>(
    'policyEditor/setPolicy',
    async (data: {title: string, content: object }) => {
        return await putRequest(`${_apiBase}set-policy/${data.title}/`, {
            content: data.content,
            title: data.title
        }, {
            'Authorization': `Bearer ${localStorage.getItem('access_token')}`,
            "Content-Type": "application/json",
            "Accept": "application/json"
        });
    }
);

const policyEditor = createSlice({
    name: 'policyEditor',
    initialState,
    reducers: {
        setSelectedPolicy: (state, action) => {
            state.selectedPolicy = action.payload;
        },
        setContent: (state, action) => {
            state.content = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder
        .addCase(setPolicy.pending, state => {state.policyLoadingState = 'setting'})
        .addCase(setPolicy.fulfilled, state => {state.policyLoadingState = 'idle'})
        .addCase(setPolicy.rejected, state => {state.policyLoadingState = 'error'})
        .addDefaultCase(() => {})
    }
});

export const { setSelectedPolicy, setContent } = policyEditor.actions;
export const { selectAll, selectById } = policyEditorAdapter.getSelectors<RootState>(state => state.organizers)

export default policyEditor.reducer;