import type { CustomFlowbiteTheme } from 'flowbite-react';

export const DropdownTheme: CustomFlowbiteTheme = {
    dropdown: {
        floating: {
            item: {
                base: "flex items-center justify-start py-3 px-5 text-2xl text-white cursor-pointer w-full hover:bg-background-accent focus:bg-background-accent focus:outline-none",
            },
            style: {
                auto: "border border-background-accent bg-white text-white"
            }
        }
    }
};