import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { InitialState } from "./interfaces";
import { EventData } from "../../../../models/interfaces";

const initialState: InitialState = {
    targetEvent: null
};

const editEvent = createSlice({
    name: 'editEvent',
    initialState,
    reducers: {
        setTargetEvent: (state, action: PayloadAction<EventData | null>) => {
            state.targetEvent = action.payload;
        }
    }
});

export default editEvent.reducer;
export const { setTargetEvent } = editEvent.actions;