import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { LangInitialState } from "./interfaces";

const initialState: LangInitialState = {
    lang: 'ru'
};

const lang = createSlice({
    name: 'lang',
    initialState,
    reducers: {
        setLang: (state, action: PayloadAction<string>) => {
            localStorage.setItem('lang', action.payload);
            state.lang = action.payload;
        }
    }
});

export default lang.reducer;
export const { setLang } = lang.actions;